import * as React from 'react';
import Title from './Title';
import ExpenseService from '../../services/expenses';
import Box from '@mui/material/Box';
import  Paper from '@mui/material/Paper';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Chip from '@mui/material/Chip';
import Pending from '@mui/icons-material/Pending';
import PaidOutlined from '@mui/icons-material/PaidOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Receipt from '../Reciept';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import renderCellExpand from '../expandCell';
import CommentsBox from '../Comments';


// const formatDate =(params)=>{
//   var msPerMinute = 60 * 1000;
//   var msPerHour = msPerMinute * 60;
//   var msPerDay = msPerHour * 24;
//   var current = new Date();
//   var elapsed = current - params.value;
//   if (elapsed < msPerMinute) {
//        return Math.round(elapsed/1000) + ' seconds ago';   
//   }

//   else if (elapsed < msPerHour) {
//        return Math.round(elapsed/msPerMinute) + ' minutes ago';   
//   }

//   else if (elapsed < msPerDay ) {
//        return Math.round(elapsed/msPerHour ) + ' hours ago';   
//   }else{
//     return params.value.toLocaleDateString();
//   }
// }


export default function Expenses() {
    const [rows, setRows] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [uState, updateState] = React.useState();
    const [recieptUrl, setRecieptUrl] = React.useState("");
    const [showingReciept, setShowingReciept] = React.useState(false);
    const [showRejectDialog, setShowRejectDialog] = React.useState(false);
    const [rejectingExpenseId, setRejectingExpenseId] = React.useState("");
    const [adminRemarks, setAdminRemarks] = React.useState("");
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const prevSelectionModel = React.useRef(selectionModel);
    const [showingCommentsBox, setShowingCommentsBox] = React.useState(false);
    const [commentsBoxData, setCommentBoxData] = React.useState({});
 
    const statusChip = (params) => {
      if(params.value==="Pending"){
        return  <Chip size="small" icon={<Pending />} label="Pending" color='info' variant="outlined" />
      }else if(params.value==="Approved"){
        return  <Chip size="small" icon={<CheckCircleOutlineIcon />} label="Approved" color='success' variant="outlined" onClick={()=>showCommentsBox(params.row)}/>
      }else if(params.value==="Rejected"){
        return <Chip size="small" icon={<ErrorOutlineOutlinedIcon />} label="Rejected" color='error' variant="outlined" onClick={()=>showCommentsBox(params.row)}/>
      }else if(params.value==="Unpaid"){
        return  <Chip size="small" icon={<Pending />} label="Not Paid" color='secondary' variant="outlined" />
      }else if(params.value==="Paid"){
        return  <Chip size="small" icon={<PaidOutlined/>} label="Paid" color='success' variant="outlined" />
      }
    }

    const showCommentsBox = (row) =>{
      setCommentBoxData({expenseId: row.id, comment: row.comments});
      setShowingCommentsBox(true);
    }

    function approve(id){
      setLoading(true);
      ExpenseService.approveExpense(id).then(forceUpdate());
      setLoading(false);
    }

    function reject(id, remarks){
      setLoading(true);
      if(remarks.trim()!==''){
        ExpenseService.makeComment(id, remarks).then(
          ExpenseService.rejectExpense(id).then(forceUpdate())
        )
      }
      ;
      setShowRejectDialog(false);
      setLoading(false);
    }

    const canApprove = (x)=>{
      return x.row.approval_status === "Approved";
    }
    const canReject = (x)=>{
      return x.row.approval_status !== "Pending";
    }
    const showReciept = (url)=>{
      setRecieptUrl(url);
      setShowingReciept(true);
    }
    
    const columns = [
      {
        field: 'id',
        width: 10,
        flex: 0.05,
        minWidth: 50,
      },
      {
        field: 'name',
        width: 200,
      },
      {
        field: 'amount',
        align: 'right',
        type: 'number',
        width: 70,
        valueFormatter: ({ value }) => `${value}₹`
      },
      {
      field: 'type',
      headerName: 'Type',
      minWidth: 120,
      flex: 0.15,
      valueGetter: (params) =>
        `${params.row.category || ''} / ${params.row.subcategory || ''}`
    },
    {
        field: 'vendor',
        headerName: 'Vendor',
        minWidth: 120,
        flex: 0.15,
        valueGetter: (params) => params.row.vendor || '' 
    },
    {
      field: 'bill_num',
      headerName: 'Bill No.',
      minWidth: 100,
      flex: 0.15,
      valueGetter: (params) => params.row.bill_num || '' 
  },
      {
        field: 'added_on',
        headerName: 'Added On',
        type: 'dateTime',
        valueGetter: ({ value }) => value && new Date(value),
        valueFormatter: (params) => params.value.toLocaleDateString(),
        flex: 0.15,
        minWidth: 100,
      },
      {
        field: 'expense_date',
        headerName: "Expense On",
        type: 'date',
        valueGetter: ({ value }) => value && new Date(value),
        valueFormatter: (params) => params.value.toLocaleDateString(),
        flex: 0.15,
        minWidth: 100,
      },
      {
        field: 'comments',
        headerName: 'Comments',
        minWidth: 150,
        flex: 0.3,
        valueGetter: (params) =>
          `${params.row.comments || ''}`,
        renderCell: renderCellExpand
      },
      {
        field: 'approval_status',
        headerName: 'Approval',
        type: 'singleSelect',
        valueOptions: ['Pending', 'Approved', 'Rejected'],
        width: 120,
        renderCell: statusChip,
      },
      {
        field: 'payment_status',
        headerName: 'Payment',
        valueOptions: ['Paid', 'Not Paid'],
        width: 120,
        renderCell: statusChip,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        flex: 0.2,
        minWidth: 100,
        getActions: (x) => [
          <GridActionsCellItem disabled={!Boolean(x.row.reciept)} onClick={()=> showReciept(x.row.reciept)} icon={<ReceiptIcon />} label="Receipt" />,
          <GridActionsCellItem onClick={() => approve(x.id)} disabled={canApprove(x)} color="success" icon={<CheckCircleOutlineIcon />} label="Approve" />,
          <GridActionsCellItem onClick={() => {setRejectingExpenseId(x.id) ;setShowRejectDialog(true);}} disabled={canReject(x)} color="error" icon={<HighlightOffIcon/>} label="Reject" />,
         ],
      }
    ]

    React.useEffect(()=>{
      setLoading(true);
      ExpenseService.getAllExpenses(10, page*10).then((data)=>{
        setRows(data.expenses);
        setRowCount(data.total_expenses);
        setLoading(false);
      });;
      setTimeout(() => {
        setSelectionModel(prevSelectionModel.current);
      });
    }, [page, uState]);

  return (
       <Box
          component="main"
          sx={{
            backgroundColor: 'white',
            width: '100vw',
            mt: '4em'
          }}
        >
          <Paper sx={{p: 2,}}>
      <Title>Expenses</Title>
      <DataGrid
      rows={rows}
      columns={columns}
      sx={{ height: '90vh', width: '100%', margin: 'auto', border: 'none', padding: '1em' }}
      pagination
      components={{ Toolbar: GridToolbar }}
      pageSize={10}
      rowsPerPageOptions={[10]}
      rowCount={rowCount}
      paginationMode="server"
      onPageChange={(newPage) => {
        prevSelectionModel.current = selectionModel;
        setPage(newPage);
      }}
      onSelectionModelChange={(newSelectionModel) => {
        setSelectionModel(newSelectionModel);
      }}
      selectionModel={selectionModel}
      loading={loading}
      />
      <Receipt open={showingReciept} image={recieptUrl} handleClose={()=> setShowingReciept(false)}/>
      <Dialog open={showRejectDialog} onClose={()=> setShowRejectDialog(false)}>
        <DialogTitle>Reject</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please add reason for rejecting this expense.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="remark"
            label="Admin Remarks"
            type="text"
            fullWidth
            variant="standard"
            value={adminRemarks}
            onChange={(e)=>{setAdminRemarks(e.target.value)}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setShowRejectDialog(false)}>Cancel</Button>
          <Button onClick={()=>reject(rejectingExpenseId, adminRemarks)}>Reject</Button>
        </DialogActions>
      </Dialog>
      </Paper>
      {showingCommentsBox ? <CommentsBox open={showingCommentsBox} handleClose={() => setShowingCommentsBox(false)} data={commentsBoxData}/> : ''}

    </Box>

  );
}
