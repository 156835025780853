import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PaidOutlined from '@mui/icons-material/PaidOutlined';
import {Link} from 'react-router-dom';

export const mainListItems = (
  <React.Fragment>
    <Link to="/" style={{ textDecoration: 'none', color: '#333333' }}>
    <ListItemButton>
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText>Expenses</ListItemText>
    </ListItemButton>
    </Link>
    <Link to="/payments" style={{ textDecoration: 'none', color: '#333333' }}>
    <ListItemButton>
      <ListItemIcon>
        <PaidOutlined />
      </ListItemIcon>
      <ListItemText>Payments</ListItemText>
    </ListItemButton>
    </Link>
  </React.Fragment>
);

