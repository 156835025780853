import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ExpenseService from '../services/expenses';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';


export default function CommentsBox(props) {
  const [reply, setReply] = React.useState("");
  const [comments, setComments] = React.useState([]);
  const [uState, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  React.useEffect(()=>{
    ExpenseService.getComments(props.data.expenseId).then((res)=>{
      setComments(res);
    }) 
  }, [uState, props.data.expenseId]);
  const makeReply = () =>{
    ExpenseService.makeComment(props.data.expenseId, reply).then(forceUpdate());
    setReply("");
  }

  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>Comments</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            {props.data.comment}
          <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
      }}
    >
      {comments.map((i)=> {
              return (
                <React.Fragment key={i.id}>
                <Divider component="li" />
                <ListItem key={i.id}>
                <ListItemAvatar>
                  <Avatar>
                    {parseInt(i.type) === 0? <AdminPanelSettingsIcon/> : <PersonIcon/>}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                sx= {()=>parseInt(i.type) === 0 && {color: '#990000'}}
                primary={
                  <React.Fragment>
                    {i.author} <Typography
                    sx={{display: 'inline', fontSize: '12px', opacity: 0.6}}
                    >
                      {new Date(i.time).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}
                      </Typography>
                  </React.Fragment>
                }
                secondary={i.text}
              />
              </ListItem>

              </React.Fragment>
              )
            })}
            </List>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="reply"
            label="Your Reply"
            variant="standard"
            value={reply}
            fullWidth
            onChange={(e) => setReply(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Close</Button>
          <Button onClick={makeReply} disabled={reply.trim()===''}>Reply</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
