import Auth from './../services/Auth';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Logout from '@mui/icons-material/Logout';
import { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';



function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) ;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar() {
    let name = Auth.getCurrentUser() ? Auth.getCurrentUser().name: null;
    if(name === null){
      return '';
    }
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ').length>=2? name.split(' ')[1][0]: ''}`,
    };
  }

export default function UserAvatar(){
    const [anchorEl, setAnchor] = useState(null);
    const navigate = useNavigate();
    const logout = (event)=>{
        Auth.logout();
        navigate("/login");
    }
    const isAdmin = Auth.isAdmin();
    let open = Boolean(anchorEl)
    return (
        <span>
        <IconButton
        id="menu-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(e) => setAnchor(e.target)}
        edge="end"
      >
        <Avatar {...stringAvatar()} />
      </IconButton>
              <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => setAnchor(null)}
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
      >
        {isAdmin?<MenuItem onClick={(e)=>navigate("/admin/dashboard")}>
        <AdminPanelSettingsIcon/>Admin
        </MenuItem> : ''}
        <MenuItem onClick={logout}>
          <Logout fontSize="small" />Logout
        </MenuItem>
      </Menu>
      </span>
    )
}