import React from 'react';
import './Login.css';
import Auth from "../../services/Auth";
import {useNavigate} from 'react-router-dom';
import logo from './logo.png';

export default function Login(){
      const [username, setUsername] = React.useState("");
      const [password, setPassword] = React.useState("");
      const navigate = useNavigate();

    const handleLogin = async (event) => {
      event.preventDefault();
        Auth.login(username, password).then(
            ()=>{
              console.log("Logged in as " +Auth.getCurrentUser()["name"]);
              navigate("/");
              
            },
            error =>{
                if(error.response.status === 401){
                  alert("Incorret username or password");
                }else{
                  alert(error.response.message);
                };
            }
        );
      };


      return (
        <div className="login-container">
        <img src={logo} alt="Aveti"/>
        <form onSubmit={handleLogin}>
            <input type="username" name="username" placeholder="Username" value={username} onChange={(e)=>setUsername(e.target.value)}/>
            <input  type="password" name="password" placeholder="Password" value={password} onChange={(e)=>setPassword(e.target.value)}/>
          <button  type="submit">Login</button>
        </form>

      </div>

    )
  }