import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
// import Chart from './Chart';
// import Unpaid from './Unpaid';
import WeeklyCycle from './WeeklyCycle';
import Container from '@mui/material/Container';


export default function Dashboard() {
  return (
  <>
          <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

          <Grid container spacing={3}>
           
          <Grid item xs={12}>
                 <WeeklyCycle />
             </Grid>
              {/* <Grid item xs={12} md={8} lg={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <Chart />
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <Unpaid />
                </Paper>
              </Grid> */}
            </Grid>
          </Container>
        </Box>
      </>
  );
}
