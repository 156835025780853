import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Grid from '@mui/material/Grid';
import ExpenseService from '../../services/expenses';
import PaidOutlined from '@mui/icons-material/PaidOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Chip from '@mui/material/Chip';
import Pending from '@mui/icons-material/Pending';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';

const statusChip = (exp) => {
  if(exp.approval_status==="Pending"){
    return  <Chip size="small" icon={<Pending />} label="Pending" color='info' variant="outlined" />
  }else if(exp.approval_status==="Rejected"){
    return  <Chip size="small" icon={<ErrorOutlineOutlinedIcon />} label="Rejected" color='error' variant="outlined" />
  }else if(exp.approval_status==="Approved" && exp.payment_status==="Unpaid"){
    return  <Chip size="small" icon={<CheckCircleOutlineIcon />} label="Approved" color='secondary' variant="outlined" />
  }else if(exp.approval_status==="Approved" && exp.payment_status==="Paid"){
    return <Chip size="small" icon={<PaidOutlined />} label="Paid" color='success' variant="outlined" />
}else{
  console.log(exp);
  return 'Unknown'
}
}

class Row extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        userExpenses: [],
        open: false,

      }
    }

    componentDidMount(){
      ExpenseService.getUserExpenses(this.props.user.user_id, this.props.dateRange).then((data)=>{
        this.setState({userExpenses: data});
      })
    }
  render(){
    const { user } = this.props;
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} id="employees">
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e)=>this.setState({open: !this.state.open})}
          >
            {this.state.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {user.user_name}
        </TableCell>
        <TableCell align="center">{user.total_amount}</TableCell>
        <TableCell align="center">{user.paid_amount}</TableCell>
        <TableCell align="right">{user.unpaid_amount}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Added On</TableCell>
                    <TableCell>Expense Date</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell align="center">Payment Status</TableCell>
                    <TableCell align="right">Expense Amount</TableCell>
                    <TableCell align="right">Comments</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.userExpenses.map((expense) => (
                    <TableRow key={expense.id}>
                      <TableCell component="th" scope="row">
                        {expense.added_on}
                      </TableCell>
                      <TableCell>
                        {expense.expense_date}
                      </TableCell>
                      <TableCell>{expense.category} / {expense.subcategory}</TableCell>
                      <TableCell align="center">{statusChip(expense)}</TableCell>
                      <TableCell align="right">
                        {expense.amount}
                      </TableCell>
                      <TableCell>{expense.comments}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
        }
}


export default function ExpensesByEmployee(){


        let today = new Date();
        let prevDate = new Date();
        prevDate.setDate(prevDate.getDate()-7);
        const [usersSummary, setUsersSummary]= React.useState([]);
        const [dateRange, setDateRange] = React.useState([prevDate, today])

      React.useEffect(()=>{
          ExpenseService.getUsersExpenseSummary(dateRange).then((data)=>{
            setUsersSummary(data);
          })
        },[dateRange]);


  return (
    <TableContainer component={Paper} id="employees" sx={{p: '2em', mt: '3em'}}>
      <Grid container spacing={1} sx={{padding: '1em', justifyContent: 'space-between'}}>
      <Grid item xs={5}>
        <Typography>Expenses By Employees</Typography>
      </Grid>
      <Grid item xs={3}>
        <DateRangePicker
        value={dateRange}
        format='dd-MM-yyyy'
        character=' - '
        placement='auto'
        onOk={(r)=>{setDateRange(r)}}
        />
      </Grid>

      </Grid>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left">Name</TableCell>
            <TableCell align="center">Total Expense</TableCell>
            <TableCell align="center">Paid Amount</TableCell>
            <TableCell align="right">Unpaid Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {usersSummary.map((user)=>{return <Row key={user.user_id} user={user} dateRange={dateRange}></Row>})}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
