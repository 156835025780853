import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems } from './listItems';
import UserAvatar from '../UserAvatar';
import Chip from '@mui/material/Chip';
import Pending from '@mui/icons-material/Pending';
import Auth from '../../services/Auth';
import PaidOutlined from '@mui/icons-material/PaidOutlined';

const drawerWidth = 210;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

const formatDate =(params)=>{
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var current = new Date();
  var elapsed = current - params.value;
  if (elapsed < msPerMinute) {
       return Math.round(elapsed/1000) + ' seconds ago';   
  }

  else if (elapsed < msPerHour) {
       return Math.round(elapsed/msPerMinute) + ' minutes ago';   
  }

  else if (elapsed < msPerDay ) {
       return Math.round(elapsed/msPerHour ) + ' hours ago';   
  }else{
    return params.value.toLocaleDateString();
  }
}

const statusChip = (params) => {
  if(!params.row.is_payment_done){
    return  <Chip size="small" icon={<Pending />} label={"Calculated on "+params.date_calculated} color='info' variant="outlined" />
  }else{
    return <Chip size="small" icon={<PaidOutlined />} label={"Paid on "+params.row.payment_date }color='success' variant="outlined" />
}
}



class ExpenseDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      payments: [],
      drawerOpen: false,
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
    }

    columns = [
      { field: 'summary_id', headerName: 'Summary Id', flex: 0.2, minWidth: 50},
      {
        field: 'cycle_start_date',
        headerName: 'From',
        type: 'date',
        flex: 0.15,
        minWidth: 80,
        valueGetter: ({ value }) => value && new Date(value),
        valueFormatter: formatDate
      },
      {
        field: 'cycle_end_date',
        headerName: 'To',
        type: 'date',
        flex: 0.15,
        minWidth: 80,
        valueGetter: ({ value }) => value && new Date(value),
        valueFormatter: formatDate
      },
        {
          field: 'payment_amount',
        headerName: 'Amount',
        flex: 0.2,
        minWidth: 70,
        type: 'number',
        valueFormatter: (params) => {
          if (params.value == null) {
            return '';
          }
          return `${params.value} ₹` ;
        }
      }
        ,
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.3,
        minWidth: 200,
        renderCell: statusChip,
      }
    ]
    
    toggleDrawer(){
      this.setState({drawerOpen: !this.state.drawerOpen});
    }

  componentDidMount(){
    const API_URL = process.env.REACT_APP_API_URL;
    var url = API_URL +"/payments";
    axios.get(
      url,{
          headers: {
          Authorization: "Bearer "+Auth.getToken(),
        }
      })
    .then(res => res.data)
    .then(
      (result) => {
        this.setState({
          isLoading: false,
          payments: result
        });
      },
      (error) => {
        this.setState({
          isLoading: false,
          error,
        });
      }
    )
}

  render(){

  return (
        <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={this.state.drawerOpen}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={this.toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(this.state.drawerOpen && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
             Payment Summary
            </Typography>
            <UserAvatar/>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={this.state.drawerOpen}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={this.toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider /> 
          <List component="nav">
            {mainListItems}
            {/* <Divider sx={{ my: 1 }} />
            {secondaryListItems} */}
          </List>
        </Drawer>

        
        <DataGrid
        rows={this.state.payments}
        columns={this.columns}
        error={this.state.error}
        loading={this.state.isLoading}
        sx={{ height: '90vh', width: '100%', margin: '3.5em auto', border: 'none', padding: '1em' }}
        getRowId={(x)=>x.summary_id}
        disableSelectionOnClick
      />
 </Box>
    </ThemeProvider>

  );
}
}

export default ExpenseDashboard;