import './App.css';
import Login from './components/user/Login';
import { BrowserRouter , Route, Routes , Navigate} from 'react-router-dom';
import ExpenseDashboard from './components/user/ExpenseDashboard';
import AdminDashboard from './components/admin/AdminDashboard';
import Dashboard from './components/admin/Dashboard';
import Auth from "./services/Auth";
import ExpensesByEmployee from './components/admin/ExpenseByEmployee';
import Expenses from './components/admin/Expenses';
import ProtectedRoute from './components/ProtectedRoute';
import Payments from './components/user/Payments';

function App() {
  return (
  <BrowserRouter>
  <Routes>
  <Route path="/" element={<ProtectedRoute/>}>
    <Route path='/' element={<ExpenseDashboard/>}/>
    <Route path='payments' element={<Payments/>}/>
  </Route>
  <Route path="login" element={<Login/>}/>
  <Route path="admin" element={Auth.isAdmin() ? <AdminDashboard/> : <Navigate to="/"></Navigate>}>
      <Route path="dashboard" element={<Dashboard/>}/>
      <Route path="employees" element={<ExpensesByEmployee/>}/>
      <Route path='expenses' element={<Expenses/>}/>
      <Route path='*' element={<Dashboard/>}/>
  </Route>
  </Routes>
  </BrowserRouter>
  )
}

export default App;
