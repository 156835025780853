import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
class AuthService {
  async login(username, password) {
    const response = await axios
          .post(API_URL + "/login", {
              username,
              password
          });
      if (response.status === 200 && response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("isAuthenticated", "true");
          return response.data;
      }else{
        return ;
      }

  }
  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("isAuthenticated");

  }
 
  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

    getToken(){
      return localStorage.getItem('token');
    }

  isLoggedIn(){
    return localStorage.getItem("isAuthenticated")==="true";
  }
  isAdmin(){
    return this.isLoggedIn() && this.getCurrentUser() &&  parseInt(this.getCurrentUser().type) === 0;
  }
}
export default new AuthService();
