import React from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Fab from '@mui/material/Fab';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ReceiptIcon from '@mui/icons-material/Receipt';
import axios from 'axios';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems } from './listItems';
import UserAvatar from '../UserAvatar';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Chip from '@mui/material/Chip';
import Pending from '@mui/icons-material/Pending';
import Auth from '../../services/Auth';
import ExpenseForm from './ExpenseForm';
import PaidOutlined from '@mui/icons-material/PaidOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Receipt from '../Reciept';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ExpenseService from '../../services/expenses';
import EditForm from './EditForm';
import renderCellExpand from '../expandCell';
import { GridToolbar } from '@mui/x-data-grid';
import CommentsBox from '../Comments';


const drawerWidth = 210;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();



// const formatDate =(params)=>{
//   var msPerMinute = 60 * 1000;
//   var msPerHour = msPerMinute * 60;
//   var msPerDay = msPerHour * 24;
//   var current = new Date();
//   var elapsed = current - params.value;
//   if (elapsed < msPerMinute) {
//        return Math.round(elapsed/1000) + ' seconds ago';   
//   }

//   else if (elapsed < msPerHour) {
//        return Math.round(elapsed/msPerMinute) + ' minutes ago';   
//   }

//   else if (elapsed < msPerDay ) {
//        return Math.round(elapsed/msPerHour ) + ' hours ago';   
//   }else{
//     return params.value.toLocaleDateString();
//   }
// }




class ExpenseDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      expenses: [],
      showReciept: false,
      formOpen: false,
      drawerOpen: false,
      showingReciept: false,
      recieptUrl: '',
      confirmationBox: false,
      deletingExpenseId: -1,
      editFormData: {},
      editFormOpen: false,
      showingCommentsBox: false,
      commentBoxData: {expenseId: '', comment: ''},
    };
    this.loadData = this.loadData.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.editExpense = this.editExpense.bind(this);
    this.deleteExpense = this.deleteExpense.bind(this);
    }

    showCommentsBox = (row) =>{
      this.setState({
        showingCommentsBox: true,
        commentsBoxData:{
          expenseId: row.id,
          comment: row.comments,
        }
      })
    }

    statusChip = (params) => {
      if(params.row.approval_status==="Pending"){
        return  <Chip size="small" icon={<Pending />} label="Pending" color='info' variant="outlined" />
      }else if(params.row.approval_status==="Rejected"){
        return  <Chip size="small" icon={<ErrorOutlineOutlinedIcon />} label="Rejected" color='error' variant="outlined" onClick={()=>this.showCommentsBox(params.row)} />
      }else if(params.row.approval_status==="Approved" && params.row.payment_status==="Unpaid"){
        return  <Chip size="small" icon={<CheckCircleOutlineIcon />} label="Approved" color='secondary' variant="outlined" onClick={()=>this.showCommentsBox(params.row)} />
      }else if(params.row.approval_status==="Approved" && params.row.payment_status==="Paid"){
        return <Chip size="small" icon={<PaidOutlined />} label="Paid" color='success' variant="outlined" onClick={()=>this.showCommentsBox(params.row)}/>
    }else{
      console.log(params);
      return 'Unknown'
    }
    }

    columns = [
      { field: 'id', headerName: 'Id', flex: 0.05, minWidth: 50},
      {
        field: 'added_on',
        headerName: 'Added On',
        type: 'dateTime',
        flex: 0.15,
        minWidth: 100,
        valueGetter: ({ value }) => value && new Date(value),
        valueFormatter: (params) => params.value.toLocaleDateString()
      },
      {
        field: 'expense_date',
        headerName: 'Expense Date',
        type: 'date',
        flex: 0.15,
        minWidth: 100,
        valueGetter: ({ value }) => value && new Date(value),
        valueFormatter: (params) => params.value.toLocaleDateString()
      },
        {
          field: 'amount',
        headerName: 'Amount',
        flex: 0.08,
        minWidth: 80,
        type: 'number',
    
        valueFormatter: (params) => {
          if (params.value == null) {
            return '';
          }
          return `${params.value} ₹` ;
        }
      }
        ,
      {
        field: 'type',
        headerName: 'Type',
        minWidth: 120,
        flex: 0.15,
        valueGetter: (params) =>
          `${params.row.category || ''} / ${params.row.subcategory || ''}`
      },
      {
        field: 'comments',
        headerName: 'Comments',
        sortable: false,
        minWidth: 150,
        flex: 0.3,
        valueGetter: (params) =>
          `${params.row.comments || ''}`,
        renderCell: renderCellExpand
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        renderCell: this.statusChip,
      },
      {
        field: 'actions',
        type: 'actions',
        flex: 0.2,
        minWidth: 100,
        getActions: (params) => [
          <GridActionsCellItem disabled={!Boolean(params.row.reciept)} onClick={()=> this.showReciept(params.row.reciept)} icon={<ReceiptIcon />} label="Receipt" />,
          <GridActionsCellItem disabled={parseInt(params.row.is_calculated) === 1 || params.row.payment_status === "Paid"} onClick={()=> this.editExpense(params.row)} icon={<EditIcon/>} label="Edit" />,
          <GridActionsCellItem disabled={parseInt(params.row.is_calculated) === 1 || params.row.payment_status === "Paid"} onClick={()=> this.setState({deletingExpenseId: params.row.id, confirmationBox: true})} icon={<DeleteIcon/>} label="Delete" />,
        ],
      }
    ];
    

    editExpense(expense){
      this.setState({
        editFormData: expense,
        editFormOpen: true,
      })
    }

    deleteExpense(id){
      ExpenseService.deleteExpense(id).then(()=>{
        this.setState({confirmationBox: false});
        this.loadData();
      });

    }
    showReciept(url){
      console.log(url);
      this.setState({
          recieptUrl: url,
          showingReciept: true,
      })
    }

    toggleDrawer(){
      this.setState({drawerOpen: !this.state.drawerOpen});
    }

  componentDidMount(){
    this.loadData();

}

loadData(){
  const API_URL = process.env.REACT_APP_API_URL;
  let user = Auth.getCurrentUser();
    var url = API_URL +"/expenses/"+ user.id;
    axios.get(
      url,{
          headers: {
          Authorization: "Bearer "+Auth.getToken(),
        }
      })
    .then(res => res.data)
    .then(
      (result) => {
        result.reverse()
        this.setState({
          isLoading: false,
          expenses: result
        });
      },
      (error) => {
        this.setState({
          isLoading: false,
          error,
        });
      }
    )
}



  render(){

  return (
        <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={this.state.drawerOpen}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={this.toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(this.state.drawerOpen && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
             Expense Dashboard
            </Typography>
            <UserAvatar/>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={this.state.drawerOpen}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={this.toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider /> 
          <List component="nav">
            {mainListItems}
            {/* <Divider sx={{ my: 1 }} />
            {secondaryListItems} */}
          </List>
        </Drawer>
        <DataGrid
        rows={this.state.expenses}
        columns={this.columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        error={this.state.error}
        loading={this.state.isLoading}
        components={{Toolbar: GridToolbar}}
        sx={{ height: '95vh', width: '100%', margin: '3.5em auto', border: 'none', padding: '1em' }}
        disableSelectionOnClick
      />
      <Fab onClick={(e) => this.setState({formOpen: true})} color="primary" aria-label="add" sx={{position: 'absolute',bottom: '7vh', right: '7vh'}}>
          <AddIcon/>
     </Fab>
 <ExpenseForm dashboard={this}/>
 {this.state.editFormOpen? <EditForm dashboard={this} formData={this.state.editFormData}/> : ''}
 <Receipt open={this.state.showingReciept} image={this.state.recieptUrl} handleClose={()=> this.setState({showingReciept: false})}/>
 </Box>
 <Dialog
        open={this.state.confirmationBox}
        onClose={() => this.setState({confirmationBox: false})}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete Expense
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this expense?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({confirmationBox: false})}>No</Button>
          <Button onClick={() => this.deleteExpense(this.state.deletingExpenseId)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {this.state.showingCommentsBox ? <CommentsBox open={this.state.showingCommentsBox} handleClose={() => this.setState({showingCommentsBox: false})} data={this.state.commentsBoxData}/> : ''}
    </ThemeProvider>

  );
}
}

export default ExpenseDashboard;