import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ExpenseService from '../../services/expenses';
import Chip from '@mui/material/Chip';
import Pending from '@mui/icons-material/Pending';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

function getDateOfISOWeek(w, y) {
  var simple = new Date(y, 0, 1 + (w - 1) * 7);
  var dow = simple.getDay();
  var ISOweekStart = simple;
  if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
}
const getDateRangeFromWeek = (weekNum, year) => {
  const monday = getDateOfISOWeek(weekNum, year);
  const options1 = { weekday: 'long', month: 'long', day: 'numeric' };
  const options2 = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const sunday = new Date(monday);
  sunday.setDate(sunday.getDate()+6);
  return <span>{monday.toLocaleDateString('en-US', options1)} <ArrowRightAltOutlinedIcon sx={{position: 'relative', top: '7px'}}/> {sunday.toLocaleDateString('en-US', options2)}</span>;
}



function Row(props) {
  const [employeesSummary, setEmployeesSummary] = React.useState([]);
  const [paymentSummary, setPaymentSummary] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [uState, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  React.useEffect(()=>{
      ExpenseService.getEmployeesSummaryByweek(props.week, props.year).then((data)=>{
        setEmployeesSummary(data);
        ExpenseService.getPaymentSummaryByWeek(props.week, props.year).then((data)=>{
          let summary = Object.assign({}, ...data.map((x) => ({[x.user_id]: x})));
          setPaymentSummary(summary);
        })
      })
  },[props.week, uState])

  const statusChip = (count, total) =>{
    // eslint-disable-next-line
    if(count==0){
    return <Chip size="small" icon={<CheckCircleOutlineIcon />} label={"All "+total+" Approved"} color='success' variant="outlined" />;
    }else{
      return <Chip size="small" icon={<Pending/>} label={count +' Pending out of ' + total} color='info' variant="outlined" />;
    }
  };

  const unpaidAmount = (amount, isCalculated, user_id) =>{
    if(Boolean(paymentSummary[user_id]) && Boolean(parseInt(paymentSummary[user_id].is_payment_done))){
      return <Chip size="small" icon={<CheckCircleOutlineIcon />} label={"Paid "+paymentSummary[user_id].payment_amount} color='success' variant="outlined" />;
    }else if(Boolean(isCalculated)){
    return <Chip size="small" icon={<CheckCircleOutlineIcon />} label={"Calculated "+amount} color='success' variant="outlined" />;
    }else{
      return <Chip size="small" icon={<Pending/>} label={'Estimated '+amount} color='info' variant="outlined" />;
    }
  };

  const setPaid = (summary) =>{

    if(!Boolean(summary)){
      return;
    }
    ExpenseService.setPaid(summary.summary_id, summary.user_id, summary.week, summary.year);
    forceUpdate();
  }


  const calculate = ()=>{
    const monday = getDateOfISOWeek(props.week, props.year);
    const sunday = new Date(monday);
    sunday.setDate(monday.getDate()+6);
    for (let i of employeesSummary){
      if(!Boolean(paymentSummary[i.user_id])){
        if(i.unapproved_expenses>0){
          alert(`Can't calculate for ${i.user_name}, approve all of their expenses first.`);
          continue;
        }
        ExpenseService.calculateWeeklyPayment(i.user_id, props.week, props.year, i.unpaid_expense_amount, monday.toISOString().slice(0, 19).replace('T', ' '), sunday.toISOString().slice(0, 19).replace('T', ' ')).then((data)=>{
        }).then(forceUpdate());
      }
    }
  }

  const canCalculateWeek = (weekNum, year)=>{
    let uncalculated = 0;
    for(let i of employeesSummary){
      if(!Boolean(paymentSummary[i.user_id])){
        uncalculated++;
      }
    }
      // eslint-disable-next-line
      if(uncalculated==0){
        return false;
      }
    const monday = getDateOfISOWeek(weekNum, year);
    const sunday = new Date(monday);
    sunday.setDate(sunday.getDate()+6)
    sunday.setHours(23);
    const today = new Date();
    return sunday < today;
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          [Week {props.week}] {getDateRangeFromWeek(props.week, props.year)}
        </TableCell>
        <TableCell align="right">{props.amount}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
            <Grid container spacing={1} sx={{padding: '1em', justifyContent: 'space-between'}}>
              <Grid item xs={5}>
                 <Typography>Weekly Summary</Typography>
              </Grid>
              <Grid item>
                <Button
                 variant="contained"
                 sx={{'marginRight':'2px', backgroundColor: 'gren'}}
                 disabled={!canCalculateWeek(props.week, props.year)}
                 onClick={calculate}
                 >Calculate
                </Button>
              </Grid>
            </Grid>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Employee</TableCell>
                    <TableCell align="center">Total Expenses</TableCell>
                    <TableCell align="right">Unpaid Expense Amount</TableCell>
                    <TableCell align="right">Pay</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeesSummary.map((employee) => (
                    <TableRow key={employee.user_id}>
                      <TableCell component="th" scope="row">
                        {employee.user_name}
                      </TableCell>
                      <TableCell align="center">{statusChip(employee.unapproved_expenses, employee.total_expenses)}</TableCell>
                      <TableCell align="right">
                        {unpaidAmount(employee.unpaid_expense_amount, paymentSummary[employee.user_id], employee.user_id)}
                      </TableCell>
                      <TableCell>
                        <IconButton aria-label="paid" disabled={!Boolean(paymentSummary[employee.user_id]) || Boolean(parseInt(paymentSummary[employee.user_id].is_payment_done))} color='success' onClick={()=> setPaid(paymentSummary[employee.user_id])}><CreditScoreOutlinedIcon/></IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function WeeklylCycle() {
  const [weeklySummary, setWeeklySummary] = React.useState([]);
  React.useEffect(()=>{
    ExpenseService.getWeeklySummary().then((data)=>{
      setWeeklySummary(data);
    })
  },[])
  return (
    <TableContainer component={Paper} >
      <Grid container spacing={1} sx={{padding: '1em', justifyContent: 'space-between'}}>
      <Grid item xs={5}>
                 <Typography>Weekly Expense Cycle</Typography>
                 </Grid>
      </Grid>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left">Cycle</TableCell>
            <TableCell align="right">Total Expense</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {weeklySummary.map((week, index) => (
            <Row key={index} week={week.week} year={week.year} amount={week.total_amount} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
