import * as React from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CardMedia from '@mui/material/CardMedia';
import { Document, Page} from 'react-pdf';
import PropTypes from 'prop-types';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
const Receipt = (props) => {
  const { open, handleClose, image } = props;
  const [numPages, setNumPages] = useState(null);
  const isPDF = image.toLowerCase().trimEnd().endsWith('.pdf');

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 4,
          top: 4,
          margin: 2,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {isPDF ? (
          <>
            <Document file={image} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
          </>
        ) : (
          <CardMedia
            component="img"
            width="auto"
            alt="Receipt"
            src={image}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

Receipt.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
};

export default Receipt;
