import React from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Auth from '../../services/Auth';
import Loader from '../Loader';
import { ListItemIcon } from '@mui/material';
import LocalAirportOutlinedIcon from '@mui/icons-material/LocalAirportOutlined';
import LocalDiningOutlinedIcon from '@mui/icons-material/LocalDiningOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import AssuredWorkloadOutlinedIcon from '@mui/icons-material/AssuredWorkloadOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import { Autocomplete } from '@mui/material';
import imageCompression from 'browser-image-compression';

const Input = styled('input')({
  display: 'none',
});


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


class ExpenseForm extends React.Component{

   constructor(props){
     super(props)
     this.state ={
       amount: "",
       category: "",
       subcategory: "",
       comments: "",
       recieptLinked: false,
       selectedFile: "",
       expenseDate: new Date(),
       isLoading: false,
       vendor: '',
       bill_num: undefined,
     }
     this.handleChange = this.handleChange.bind(this);
     this.handleClose = this.handleClose.bind(this);
     this.handleClickOpen = this.handleClickOpen.bind(this);
     this.getSubCategories = this.getSubCategories.bind(this);
     this.saveExpense = this.saveExpense.bind(this);

     this.categories ={
      "Travel": [ "Auto", "Bus","Cab  Rental", "Flight", "Fuel","Train"],
      "Food": ["Breakfast", "Dinner", "Lunch", "Eat Out"],
      "Bills": ["Electricity", "Gas", "Internet", "Mobile"],
      "Office":[ "Computer & Electronics", "Credit Card", "Event", "Maintainence","Prints", "Rent" ,"Stationery", "Software and Licenses"],
      "Compliance":["Ptax", "PF", "TDS", "ESI", "GST"],
      "Other":["Hotel" ,"Miscellaneous"]
    }
      this.catIcons = {
        "Travel": <LocalAirportOutlinedIcon fontSize='small'/>,
        "Food": <LocalDiningOutlinedIcon/>,
        "Bills": <ReceiptOutlinedIcon/>,
        "Office": <BusinessOutlinedIcon/>,
        "Compliance": <AssuredWorkloadOutlinedIcon/>,
        "Other": <CurrencyRupeeOutlinedIcon/>
      }
        this.vendors = [
          "Amazon",
          "Gadget Zone",
          "IBall",
          "IT clinic",
          "Colour Point",
        ]
    }
    

   handleChange(event){
     const { name, value } = event.target;
     this.setState({
       ...this.state,
       [name]: value,
     });
   }
   handleClickOpen() {
    this.props.dashboard.setState({"formOpen": true})
  };
  
   handleClose () {
    this.props.dashboard.setState({"formOpen": false})
  };
  
async saveExpense() {
  this.setState({ isLoading: true });
  const API_URL = process.env.REACT_APP_API_URL;

  try {
    let formData = new FormData();

    if (this.state.recieptLinked) {
      const isImage = this.state.selectedFile.type.startsWith('image/');

      if (isImage) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        };

        const compressedFile = await imageCompression(this.state.selectedFile, options);
        const file = new File([compressedFile], this.state.selectedFile.name, { type: this.state.selectedFile.type });
        formData.append("file", file);
      } else {
        formData.append("file", this.state.selectedFile);
      }

      const uploadResponse = await axios.post(`${API_URL}/upload`, formData, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
          "Content-Type": "multipart/form-data",
        },
      });

      const receipt = uploadResponse.data;

      await this.addExpenseToServer(receipt);
    } else {
      await this.addExpenseToServer(null);
    }

    this.handleClose();
    this.setState({
      amount: "",
      category: "",
      subcategory: "",
      comments: "",
      recieptLinked: false,
      selectedFile: "",
      vendor: "",
      bill_num: null,
      expenseDate: new Date(),
    });

    this.props.dashboard.loadData();
  } catch (error) {
    alert(error.message);
  } finally {
    this.setState({ isLoading: false });
  }
}

async addExpenseToServer(receipt) {
    const API_URL = process.env.REACT_APP_API_URL;
    const data = {
        amount: this.state.amount,
        category: this.state.category,
        subcategory: this.state.subcategory,
        comments: this.state.comments,
        reciept: receipt,
        vendor: this.state.vendor,
        bill_num: this.state.bill_num,
        expenseDate: this.state.expenseDate.toISOString().slice(0, 19).replace('T', ' '),
    };

    const response = await axios.post(`${API_URL}/addExpense`, data, {
        headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
        },
    });
}

    getSubCategories(cat){
      if(cat===""){
        return ""
      }
      return this.categories[cat].map((value, i)=>{return <MenuItem key={i} value={value}>{value}</MenuItem>})
    }

  render(){
    const categories = Object.keys(this.categories).map((value, i)=>{
      return (<MenuItem
        key={i}
        value={value}>
          <ListItemIcon>{this.catIcons[value]}</ListItemIcon><span>{value}</span>
        </MenuItem>)
    });

    return (
      <Dialog
      fullScreen
      open={this.props.dashboard.state.formOpen}
      onClose={this.handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={this.handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Add Expense
          </Typography>
          <Button autoFocus color="inherit" onClick={this.saveExpense}>
            Save
          </Button>
        </Toolbar>
      </AppBar>
        <Box
        component="form"
        sx={{ '& > :not(style)': { m: 1 ,width: 300}, m: '1em'}}
        >

          <TextField
            required
            id="amount"
            label="Amount"
            type="number"
            name="amount"
            error={this.state.amount%1!==0}
            value={this.state.amount}
            onChange={this.handleChange}
          />
    <FormControl sx={{ m: 1, minWidth: 120 }}>
    <InputLabel id="category-label">Category*</InputLabel>
    <Select
      labelId="category-label"
      id="category-select"
      value={this.state.category}
      label="Category"
      name="category"
      onChange={this.handleChange}
      required
    >
      {categories}
    </Select>
  </FormControl>
    <FormControl sx={{ m: 1, minWidth: 120 }}>
    <InputLabel id="subcategory-label">Sub category*</InputLabel>
    <Select
      labelId="subcategory-label"
      id="sub-category-select"
      value={this.state.subcategory}
      label="Sub Category"
      name="subcategory"
      onChange={this.handleChange}
      required
    >
      {this.getSubCategories(this.state.category)}
    </Select>
    </FormControl>

    <Autocomplete
        autoSelect
        id="vendor"
        freeSolo
        options={this.vendors}
        onChange={(event, value) => this.setState({vendor: value})}
        renderInput={(params) => <TextField name='vendor' {...params} label="vendor" />}
      />
    <TextField
      id="bill_num"
      label="Bill No."
      name="bill_num"
      value={this.state.bill_num}
      onChange={this.handleChange}
    />
    <TextField
      id="comments"
      label="Comments"
      name="comments"
      value={this.state.comments}
      onChange={this.handleChange}
      multiline
      rows={4}
      helperText={`${this.state.comments.length}/500`}
      error={this.state.comments.length>500}
      inputProps={{
        maxlength: 500
      }}
      />
  
<LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
          disableFuture
          label="Expense Date*"
          openTo="day"
          views={['year', 'month', 'day']}
          value={this.state.expenseDate}
          onChange={(newValue) => {
            this.setState({expenseDate: newValue});
          }}
          inputFormat="dd/MM/yyyy"
          renderInput={(params) => <TextField {...params} />}

          required
        />
           </LocalizationProvider>
        <FormControl>
        <label htmlFor="upload-reciept">
        <Input accept="image/*, application/pdf" id="upload-reciept" type="file" onChange={(e) => {this.setState({selectedFile: e.target.files[0], recieptLinked: true}); console.log(e.target.files)}}/>
        <Button variant="outlined" component="span" startIcon ={<PhotoCamera/>}>
          Add Receipt
        </Button>
        </label>
        <FormHelperText>{this.state.recieptLinked? this.state.selectedFile.name: null}</FormHelperText>
        </FormControl>

      </Box>
      <Loader isLoading={this.state.isLoading}/>
      </Dialog>
    );
}
}
export default ExpenseForm;