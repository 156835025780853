import axios from "axios";
import Auth from "./Auth";
const API_URL = process.env.REACT_APP_API_URL;

class ExpenseService{
     _getReq(url){
        return axios.get(url,{
            headers: {
            Authorization: "Bearer "+Auth.getToken(),
            }
        }).then((res)=>{
            return res.data;

        }).catch((err)=>{
            console.log(err);
            return [];
        }
        );
}
    getAllExpenses(limit=10, offset=0){
        return this._getReq(API_URL+"/admin/allExpenses?limit="+limit+"&offset="+offset);
    }

    getUsersExpenseSummary(dateRange){
        return this._getReq(API_URL+`/admin/usersExpenseSummary?from=${dateRange[0].toISOString().slice(0, 19).split('T')[0]}&to=${dateRange[1].toISOString().slice(0, 19).split('T')[0]}`);
    }
    getUserExpenses(user_id, dateRange){
        return this._getReq(API_URL+"/admin/userExpenses/"+user_id+`?from=${dateRange[0].toISOString().slice(0, 19).split('T')[0]}&to=${dateRange[1].toISOString().slice(0, 19).split('T')[0]}`);
    }
    getWeeklySummary(){
        return this._getReq(API_URL+"/admin/weeklySummary");
    }
    approveExpense(expenseId){
        return this._getReq(API_URL+"/admin/approveExpense/"+expenseId);
    }
    rejectExpense(expenseId){
        return this._getReq(API_URL+`/admin/rejectExpense?expenseId=${expenseId}`);
    }
    getEmployeesSummaryByweek(week, year){
        return this._getReq(API_URL+`/admin/employeesSummaryByweek?week=${week}&year=${year}`)
    }
    getPaymentSummaryByWeek( week, year){
        return this._getReq(API_URL+`/admin/getPaymentSummaryByWeek?week=${week}&year=${year}`)
    }
    calculateWeeklyPayment(user_id, week, year, amount, start, end){
        return this._getReq(API_URL+`/admin/calculateWeeklyPayment?week=${week}&year=${year}&userId=${user_id}&amount=${amount}&cycleStartDate=${start}&cycleEndDate=${end}`)
    }
    setPaid(summaryId, userId, week, year){
        return this._getReq(API_URL+`/admin/setPaid?summaryId=${summaryId}&week=${week}&year=${year}&userId=${userId}`);
    }
    deleteExpense(expenseId){
        return this._getReq(API_URL+`/deleteExpense?expenseId=${expenseId}`);
    }
    getComments(expenseId){
        return this._getReq(API_URL+`/comments?expenseId=${expenseId}`);
    }
    makeComment(expenseId, comment){
        return this._getReq(API_URL+`/makeComment?expenseId=${expenseId}&comment=${comment}`);
    }
}

export default new ExpenseService();

